import React from 'react'

const Battery: React.FC<React.HTMLAttributes<SVGElement>> = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Style=Fill">
      <g id="Vector">
        <path
          clipRule="evenodd"
          d="M15 5.5H9C8.72386 5.5 8.5 5.72386 8.5 6V20C8.5 20.2761 8.72386 20.5 9 20.5H15C15.2761 20.5 15.5 20.2761 15.5 20V6C15.5 5.72386 15.2761 5.5 15 5.5ZM9 4C7.89543 4 7 4.89543 7 6V20C7 21.1046 7.89543 22 9 22H15C16.1046 22 17 21.1046 17 20V6C17 4.89543 16.1046 4 15 4H9Z"
          fill="black"
          fillRule="evenodd"
        />
        <path
          d="M10 3C10 2.44772 10.4477 2 11 2H13C13.5523 2 14 2.44772 14 3V4H10V3Z"
          fill="black"
        />
        <path
          d="M9 9H15V19.75C15 19.8881 14.8881 20 14.75 20H9.25C9.11193 20 9 19.8881 9 19.75V9Z"
          fill="black"
        />
      </g>
    </g>
  </svg>
)

export default Battery
