interface TriangleVectorProps {
  className?: string
}

const TriangleVector: React.FC<TriangleVectorProps> = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="59"
    viewBox="0 0 69 59"
    width="69"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.8899 3.19038L29.8887 3.19257L1.61661 51.3571L29.8899 3.19038ZM29.8899 3.19038C31.5696 0.31228 35.724 0.26104 37.4793 3.09253L67.3273 51.2575C67.3274 51.2576 67.3274 51.2577 67.3275 51.2578C69.153 54.2064 67.038 58 63.5412 58H5.45067C2.00546 58 -0.110684 54.3074 1.61614 51.358L29.8899 3.19038Z"
      opacity="0.5"
      stroke="#6E3264"
      strokeWidth="2"
    />
  </svg>
)

export default TriangleVector
