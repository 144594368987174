export default function catalogCategoryToProductCardAdapter(
  products: APIResponse.CatalogCategory['data']['heroProducts']
) {
  return products.map(
    ({
      id,
      brand,
      features,
      heroPath,
      description,
      images,
      name,
      shortName,
      pricing,
      rating,
      reviewCount,
      scoredAttributes,
    }) => ({
      priceLabel: 'What You Pay',
      brand,
      features,
      // The path that comes back from the API is `/gp/...`. If we're on `/health`, we need
      // to update the path to `/health/gp/...` so we remain on the same sub-path. Once `/health`
      // is live and we're redirecting health.babylist.com requests to it, we should figure out
      // how to communicate the correct URL/path in the API.
      // TODO-health-domain-change
      heroPath: `/health/${heroPath}`,
      id,
      imageDescription: description,
      imageUrl: images.url,
      name,
      shortName,
      price: pricing.current,
      rating: Number(rating),
      reviewCount,
      scoredAttributes,
    })
  )
}
