import React from 'react'

const FlowerCheck: React.FC<React.HTMLAttributes<SVGElement>> = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 25 24"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M15.2854 4.13895C16.54 3.79806 17.9375 4.11953 18.9236 5.10564C19.9097 6.09175 20.2312 7.48927 19.8903 8.74387C21.0185 9.38998 21.7793 10.6055 21.7793 12C21.7793 13.3945 21.0185 14.61 19.8904 15.2561C20.2312 16.5107 19.9098 17.9082 18.9237 18.8943C17.9375 19.8804 16.54 20.2019 15.2854 19.861C14.6393 20.9892 13.4238 21.75 12.0293 21.75C10.6347 21.75 9.41926 20.9891 8.77316 19.861C7.51857 20.2019 6.12107 19.8804 5.13497 18.8943C4.14887 17.9082 3.8274 16.5107 4.16827 15.2561C3.04013 14.61 2.2793 13.3945 2.2793 12C2.2793 10.6054 3.04015 9.38996 4.1683 8.74386C3.82743 7.48927 4.1489 6.09178 5.13501 5.10567C6.12111 4.11957 7.51859 3.7981 8.77318 4.13897C9.41928 3.01083 10.6348 2.25 12.0293 2.25C13.4238 2.25 14.6393 3.01083 15.2854 4.13895ZM15.5443 10.5453C15.8454 10.2609 15.859 9.78617 15.5746 9.48503C15.2901 9.1839 14.8155 9.17033 14.5143 9.45474L10.794 12.9684L9.54427 11.7881C9.24313 11.5037 8.76845 11.5172 8.48404 11.8184C8.19963 12.1195 8.21319 12.5942 8.51433 12.8786L10.279 14.5453C10.5681 14.8182 11.0199 14.8182 11.309 14.5453L15.5443 10.5453Z"
      fill="#FF6D4E"
      fillRule="evenodd"
    />
  </svg>
)

export default FlowerCheck
