// eslint-disable-next-line import/no-extraneous-dependencies
import { Image, StarRating } from 'baby-design'
import styles from './ProductTile.styles.scss'

const ProductTile = ({
  product,
  onClick,
}: {
  product: APIResponse.PopularPumps
  onClick: () => void
}) => {
  const { brand, images, name, rating, reviewCount } = product

  return (
    <button
      aria-label={`View breast pump details for ${name} by ${brand}`}
      className={styles.ProductTile}
      type="button"
      onClick={onClick}
    >
      <div className={styles.image_container}>
        <Image alt={`${brand} ${name}`} src={images.thumbnailUrl} />
      </div>
      <div className={styles.content_container}>
        <h3
          aria-label={`${brand} ${name}`}
          className={styles.brand}
          title={`${brand} ${name}`}
        >
          {brand}
        </h3>
        <p className={styles.name}>{name}</p>
        <div className={styles.rating_container}>
          <StarRating rating={rating || 0} reviewCount={reviewCount || 0} />
        </div>
      </div>
    </button>
  )
}

export default ProductTile
