import { useState, FC } from 'react'
import {
  AppBar as MuiAppBar,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Theme,
  Toolbar,
  useMediaQuery,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import {
  BABYLIST_URL,
  DME_ZENDESK_URL,
  HOME_PATH,
  PARTS_HOME_PATH,
} from 'bl-health/constants'
import { useLayout } from 'bl-health/widgets/Layout'
import { useDMEStore } from 'bl-health/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from 'baby-design'
import classNames from 'classnames'
import { INSURANCE_PICKER_SECTION_ID } from 'bl-health/Homepage/sections/InsurancePickerSection/InsurancePickerSection'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import useStyles from './styles'
import PhoneSupport from '../../../dme/views/OrderView/components/PhoneSupport'
import { ExternalIconSvg, LinkButton } from './components'

interface AppBarProps {
  milkBagsForPartsBuyersOverride?: boolean
  isHealthRedesign?: boolean
}

const AppBar: FC<AppBarProps> = ({
  milkBagsForPartsBuyersOverride,
  isHealthRedesign,
}) => {
  const classes = useStyles()
  const uiSettings = useLayout()
  const strategy = useDMEStore().context?.strategy
  const milkBagsForPartsBuyers =
    milkBagsForPartsBuyersOverride ?? uiSettings?.milkBagsForPartsBuyers
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const tracker = useTracking()

  const onGetStartedClick = () => {
    tracker.trackEvent({
      event: track.homepageCtaClicked,
      healthSection: 'Health navigation bar',
    })
  }

  return (
    <div className={classes.root}>
      <MuiAppBar className={classes.appBar} elevation={4} position="sticky">
        <Toolbar>
          <Grid container direction="row">
            <Grid item xs className={classes.imageMenuContainer}>
              <IconButton
                aria-label="menu"
                className={classes.menuButton}
                onClick={() => setIsDrawerOpen(true)}
              >
                <MenuIcon />
              </IconButton>
              <Link
                aria-label="Visit homepage"
                className={classNames(!isHealthRedesign && classes.hiddenSm)}
                href={HOME_PATH}
              >
                <img
                  alt="Babylist Health Logo"
                  src="//res.cloudinary.com/babylist/image/upload/v1662486788/DME/logo_babylist-health.svg"
                />
              </Link>
            </Grid>
            {!isHealthRedesign && (
              <Grid item xs className={`${classes.title} ${classes.hiddenMd}`}>
                <Link aria-label="Visit homepage" href={HOME_PATH}>
                  <img
                    alt="Babylist Health Logo"
                    src="//res.cloudinary.com/babylist/image/upload/v1662486788/DME/logo_babylist-health.svg"
                  />
                </Link>
              </Grid>
            )}
            <Grid
              item
              className={classes.rightGrid}
              xs={isDesktop ? 'auto' : true}
            >
              <LinkButton
                className={
                  isHealthRedesign
                    ? classes.healthRedesignLinkButton
                    : undefined
                }
                href={HOME_PATH}
                target="_self"
                underline={strategy === 'pumps'}
              >
                Breast Pumps
              </LinkButton>
              <LinkButton
                className={
                  isHealthRedesign
                    ? classes.healthRedesignLinkButton
                    : undefined
                }
                href={PARTS_HOME_PATH}
                target="_self"
                underline={strategy === 'parts'}
              >
                {milkBagsForPartsBuyers
                  ? 'Parts and Milk Bags'
                  : 'Replacement Parts'}
              </LinkButton>
              <LinkButton
                className={
                  isHealthRedesign
                    ? classes.healthRedesignLinkButton
                    : undefined
                }
                href={BABYLIST_URL}
                icon={isHealthRedesign ? 'heartbeat-external' : 'external'}
              >
                Babylist.com
              </LinkButton>
              {isHealthRedesign && (
                <Button
                  href={`#${INSURANCE_PICKER_SECTION_ID}`}
                  size="sm"
                  variant="primary"
                  onClick={onGetStartedClick}
                >
                  Get started
                </Button>
              )}
            </Grid>
          </Grid>

          <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
            <List className={classes.list}>
              <ListItem disableGutters className={classes.menuHeader}>
                <IconButton
                  className={classes.closeButton}
                  edge="start"
                  onClick={() => setIsDrawerOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
                <img
                  alt="Babylist Health Logo"
                  src="//res.cloudinary.com/babylist/image/upload/v1662486788/DME/logo_babylist-health.svg"
                  width={118}
                />
              </ListItem>
              <ListItem
                className={classes.listItemLink}
                component="a"
                href={HOME_PATH}
              >
                <ListItemText primary="Breast Pumps" />
              </ListItem>
              <ListItem
                className={classes.listItemLink}
                component="a"
                href={PARTS_HOME_PATH}
              >
                <ListItemText
                  primary={
                    milkBagsForPartsBuyers
                      ? 'Parts and Milk Bags'
                      : 'Replacement Parts'
                  }
                />
              </ListItem>
              <ListItem
                className={classes.listItemLink}
                component="a"
                href={DME_ZENDESK_URL}
                target={isHealthRedesign ? '_blank' : '_self'}
              >
                <ListItemText primary="Help Center" />
              </ListItem>
              <ListItem>
                <PhoneSupport
                  className={classes.phoneSupport}
                  underline="none"
                />
              </ListItem>
              <Divider className={classes.menuDivider} />
              <ListItem
                className={classes.listItemLink}
                component="a"
                href={BABYLIST_URL}
                target={isMobile ? '_self' : '_blank'}
              >
                <ListItemText
                  primary={
                    <>
                      Babylist.com
                      <ExternalIconSvg
                        className={classes.listItemExternalIcon}
                      />
                    </>
                  }
                  secondary="Registry and Shop"
                />
              </ListItem>
            </List>
          </Drawer>
        </Toolbar>
      </MuiAppBar>
    </div>
  )
}

export default AppBar
