import { FC } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from 'baby-design'
// eslint-disable-next-line import/no-extraneous-dependencies
import { BreastPump, GiftShipment, TransferReg } from 'baby-design/icons'
import { FirstViewWaypoint } from 'bl-health/Homepage/components/FirstViewWaypoint/FirstViewWaypoint'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import styles from './HowBLHWorksSection.styles.scss'
import DetailCard from './components/DetailCard/DetailCard'
import { INSURANCE_PICKER_SECTION_ID } from '../InsurancePickerSection/InsurancePickerSection'

const HowBLHWorksSection: FC = () => {
  const tracker = useTracking()

  const onView = () => {
    tracker.trackEvent({
      event: track.homepageSectionViewed,
      healthSection: 'How BLH works',
    })
  }

  const onClick = () => {
    tracker.trackEvent({
      event: track.homepageCtaClicked,
      healthSection: 'How BLH works',
    })
  }

  return (
    <FirstViewWaypoint onView={onView}>
      <div className={styles.HowBLHWorksSection}>
        <div className={styles.grid_layout}>
          <h2 className={styles.section_title}>How Babylist Health works</h2>
          <div className={styles.detail_cards}>
            <DetailCard
              description="Enter your state and insurance provider to see a list of eligible pumps."
              icon={<TransferReg />}
              title="Share your info"
            />
            <DetailCard
              description="Once you find one you like, we'll verify your insurance and handle the paperwork."
              icon={<BreastPump />}
              title="Choose a pump"
            />
            <DetailCard
              description="A breast pump will show up at your doorstep (with free shipping to boot)!"
              icon={<GiftShipment />}
              title="Check your doorstep"
            />
          </div>
          <div className={styles.button_container}>
            <Button
              href={`#${INSURANCE_PICKER_SECTION_ID}`}
              size="md"
              variant="primary"
              onClick={onClick}
            >
              Get started
            </Button>
          </div>
        </div>
      </div>
    </FirstViewWaypoint>
  )
}

export default HowBLHWorksSection
