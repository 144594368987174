import { FC } from 'react'
import InsurancePicker from 'bl-health/components/InsurancePicker/InsurancePicker'
import { FirstViewWaypoint } from 'bl-health/Homepage/components/FirstViewWaypoint/FirstViewWaypoint'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import styles from './InsurancePickerSection.styles.scss'
import CircleVector from './components/CircleVector/CircleVector.svg'
import TriangleVector from './components/TriangleVector/TriangleVector.svg'
import FlowerVector from './components/FlowerVector/FlowerVector.svg'

export const INSURANCE_PICKER_SECTION_ID = 'insurance-form'

const InsurancePickerSection: FC = () => {
  const tracker = useTracking()

  const onView = () => {
    tracker.trackEvent({
      event: track.homepageSectionViewed,
      healthSection: 'Insurance form',
    })
  }

  return (
    <FirstViewWaypoint onView={onView}>
      <div
        className={styles.InsurancePickerSection}
        id={INSURANCE_PICKER_SECTION_ID}
      >
        <div className={styles.grid_layout}>
          <div className={styles.section_content}>
            <div className={styles.section_text}>
              <h1 className={styles.section_title}>
                Get a free breast pump through insurance in just a few minutes.
              </h1>
              <p className={styles.section_subtitle}>
                We'll take care of contacting your healthcare provider and
                billing your insurance.
              </p>
            </div>
            <div className={styles.insurance_picker_container}>
              <InsurancePicker />
            </div>
          </div>
        </div>
        <CircleVector aria-hidden className={styles.circle_vector} />
        <TriangleVector aria-hidden className={styles.triangle_vector} />
        <FlowerVector aria-hidden className={styles.flower_vector} />
      </div>
    </FirstViewWaypoint>
  )
}

export default InsurancePickerSection
