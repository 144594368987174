import { FC } from 'react'
import styles from './DetailCard.styles.scss'

interface DetailCardProps {
  title: string
  description: string
  icon: React.ReactNode
}

const DetailCard: FC<DetailCardProps> = ({ title, description, icon }) => (
  <section className={styles.DetailCard}>
    <div className={styles.card_icon}>{icon}</div>
    <div className={styles.card_content}>
      <h3 className={styles.card_title}>{title}</h3>
      <h4 className={styles.card_description}>{description}</h4>
    </div>
  </section>
)

export default DetailCard
