import { FC } from 'react'
import styles from './Sticker.styles.scss'
import StickerSVG from './Sticker.svg'

interface StickerProps {
  children: React.ReactNode
}

const Sticker: FC<StickerProps> = ({ children }) => (
  <div className={styles.Sticker}>
    <StickerSVG />
    <div className={styles.sticker_content}>{children}</div>
  </div>
)

export default Sticker
