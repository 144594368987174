import { FC } from 'react'
import { FirstViewWaypoint } from 'bl-health/Homepage/components/FirstViewWaypoint/FirstViewWaypoint'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import styles from './TestimonialsSection.styles.scss'
import ReviewCard from './components/ReviewCard/ReviewCard'

const TestimonialsSection: FC = () => {
  const tracker = useTracking()

  const onView = () => {
    tracker.trackEvent({
      event: track.homepageSectionViewed,
      healthSection: 'Testimonials',
    })
  }

  return (
    <FirstViewWaypoint onView={onView}>
      <div className={styles.TestimonialsSection}>
        <div className={styles.grid_layout}>
          <div className={styles.section_headers}>
            <h2 className={styles.section_heading}>
              Don't take our word for it
            </h2>
            <p className={styles.section_subheading}>
              Here's what other parents say about Babylist Health.
            </p>
          </div>
          <div className={styles.review_cards}>
            <ReviewCard
              author="Laura P."
              quote="They made it very easy to get a breast pump covered by insurance.
              They handled everything for me including verifying my insurance,
              the selection I could choose from and getting my prescription. It
              really couldn't have been easier."
            />
            <ReviewCard
              author="Rebecca C."
              quote="The follow up emails to receive new parts and more breast milk
              storage bags was above and beyond! You guys could not have made
              the Babylist Health experience more enjoyable or easier. I would
              recommend to every new mom!"
            />
            <ReviewCard
              author="Mary C."
              quote="It was so easy to pick out, order and receive my breast pump. It basically took all the admin work out of it. I didn't have to worry about working with my insurance company or doctor to get it covered because Babylist did both for me. Thank you!!!"
            />
          </div>
        </div>
      </div>
    </FirstViewWaypoint>
  )
}

export default TestimonialsSection
