import { FC } from 'react'
import Footer from 'baby-ui/compounds/Footer'
import { FooterNav as footerNavGroups } from 'bl-health/widgets/Layout/constants'

import LegacyMuiWrapper from './LegacyMuiWrapper'

const LegacyFooter: FC = () => (
  <LegacyMuiWrapper>
    <Footer isHealthRedesign navGroups={footerNavGroups} />
  </LegacyMuiWrapper>
)

export default LegacyFooter
