// eslint-disable-next-line import/no-extraneous-dependencies
import { FlowerCheck } from 'baby-design/icons'
import styles from './ListItem.styles.scss'

interface ListItemProps {
  children: React.ReactNode
}

const ListItem: React.FC<ListItemProps> = ({ children }) => (
  <div className={styles.ListItem}>
    <FlowerCheck />
    <h3 className={styles.item_text}>{children}</h3>
  </div>
)

export default ListItem
