import React from 'react'

import css from './Accordion.styles.scss'
import { AccordionProps, AccordionComponent } from './Accordion.types'
import Section from './Section/Section'

const Accordion: React.FC<AccordionProps> & AccordionComponent = ({
  children,
}) => <div className={css.Accordion}>{children}</div>

Accordion.Section = Section

export default Accordion
