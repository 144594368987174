interface FlowerVectorProps {
  className?: string
}

const FlowerVector: React.FC<FlowerVectorProps> = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="102"
    viewBox="0 0 141 102"
    width="141"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M5.8414 35.7111C9.97812 29.6031 16.3498 25.9013 23.1654 24.9389C19.8556 24.2967 16.6075 22.9955 13.6171 20.9982C1.77927 13.0916 -1.53598 -2.72919 6.21231 -14.3386C13.9606 -25.9479 29.8383 -28.9496 41.6762 -21.043C43.7088 -19.6854 45.4902 -18.0945 47.0074 -16.3316C43.5501 -24.1116 44.0623 -33.3943 49.1834 -40.8667C57.0738 -52.3799 72.9175 -55.2356 84.5713 -47.245C94.6621 -40.3262 98.3028 -27.544 93.9755 -16.7655C102.059 -26.9759 116.953 -29.2548 128.124 -21.6848C139.821 -13.758 143.007 1.96585 135.239 13.4355C130.692 20.1492 123.4 23.9171 115.789 24.2979C120.091 24.6342 124.385 26.0528 128.249 28.6337C140.087 36.5403 143.403 52.3611 135.654 63.9704C127.906 75.5798 112.028 78.5815 100.19 70.6749C98.0084 69.2175 96.1159 67.4912 94.5289 65.572C97.9803 73.3139 97.4928 82.5231 92.4214 89.923C84.5903 101.35 68.7947 104.135 57.1409 96.1446C46.5329 88.871 43.0132 75.1759 48.3734 64.1583C48.3165 64.2445 48.2591 64.3305 48.201 64.4163C40.3742 75.9729 24.5469 78.9154 12.8496 70.9887C1.15231 63.062 -1.98536 47.2677 5.8414 35.7111Z"
      fillRule="evenodd"
      opacity="0.5"
      stroke="#6E3264"
      strokeWidth="2"
    />
  </svg>
)

export default FlowerVector
