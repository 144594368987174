import React from 'react'

const TransferReg: React.FC<React.HTMLAttributes<SVGElement>> = () => (
  <svg
    fill="none"
    height="64"
    viewBox="0 0 64 64"
    width="64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_410_1724)">
      <path
        d="M59.1269 8.02945L34.398 1.84945C31.6606 1.16945 28.8824 2.78945 28.1904 5.47945L20.6598 34.5895C19.9678 37.2795 21.6163 40.0095 24.3538 40.6895L49.0827 46.8695C51.8202 47.5495 54.5984 45.9295 55.2904 43.2395L62.821 14.1295C63.513 11.4395 61.8644 8.70945 59.1269 8.02945Z"
        fill="url(#paint0_linear_410_1724)"
      />
      <path
        d="M18.716 17.5995L3.32918 20.8895C1.75182 21.2295 0.744349 22.7595 1.09035 24.3195L8.78378 59.1195C9.12979 60.6695 10.6868 61.6595 12.2743 61.3195L27.6612 58.0294C29.2385 57.6894 30.246 56.1595 29.9 54.5994L22.2066 19.7995C21.8606 18.2495 20.3036 17.2595 18.716 17.5995Z"
        fill="url(#paint1_linear_410_1724)"
      />
      <path
        d="M7.94931 39.4695C8.10196 40.1695 8.33602 40.7395 8.77361 41.2695C10.1474 43.1595 12.2947 44.2695 14.5539 44.9295C15.2255 45.0995 16.0396 45.4495 16.752 45.2895C17.4643 45.1295 18.0546 44.4895 18.5736 43.9695C20.1713 42.5895 21.6062 40.9295 22.2269 38.9295C22.8477 36.9295 22.2371 34.6595 20.3952 33.4895C18.2988 32.1695 15.6427 33.0495 14.4928 35.0595C13.3836 34.3595 12.0403 34.0195 10.7478 34.3995C8.54972 34.9695 7.46084 37.2795 7.92896 39.3695L7.94931 39.4695Z"
        fill="#EB779F"
      />
      <path
        d="M30.877 34.0195C30.6734 34.0195 30.4699 33.9895 30.2664 33.9495C28.9434 33.6195 28.1497 32.2995 28.4855 30.9995C28.7603 29.9195 29.7474 29.1695 30.877 29.1695C31.0805 29.1695 31.284 29.1995 31.4876 29.2495C32.1287 29.4095 32.6579 29.7995 33.0039 30.3595C33.3499 30.9195 33.4414 31.5695 33.2786 32.1995C33.0039 33.2795 32.0167 34.0295 30.8871 34.0295H30.877V34.0195Z"
        fill="#DB6B4D"
      />
      <path
        d="M33.299 24.6695C33.0954 24.6695 32.8919 24.6395 32.6884 24.5895C31.3654 24.2595 30.5717 22.9395 30.9075 21.6395C31.1823 20.5595 32.1694 19.8095 33.299 19.8095C33.5025 19.8095 33.706 19.8395 33.9096 19.8895C34.5507 20.0495 35.09 20.4395 35.4259 20.9995C35.7617 21.5595 35.8634 22.2095 35.7006 22.8395C35.4259 23.9195 34.4387 24.6695 33.3091 24.6695H33.299Z"
        fill="#64AF68"
      />
      <path
        d="M35.7108 15.3195C35.5073 15.3195 35.3037 15.2895 35.1002 15.2395C33.7773 14.9095 32.9835 13.5895 33.3193 12.2895C33.5941 11.2095 34.5812 10.4595 35.7108 10.4595C35.9143 10.4595 36.1179 10.4895 36.3214 10.5395C36.9625 10.6995 37.5019 11.0895 37.8377 11.6495C38.1735 12.2095 38.2753 12.8595 38.1125 13.4895C37.8377 14.5695 36.8506 15.3195 35.721 15.3195H35.7108Z"
        fill="#F6D2FB"
      />
      <path
        d="M55.7076 18.8898C55.6262 18.8898 55.5448 18.8898 55.4532 18.8598L45.1851 16.2998C44.6356 16.1598 44.3099 15.6198 44.4524 15.0898C44.5949 14.5498 45.1444 14.2298 45.6939 14.3698L55.962 16.9298C56.5115 17.0698 56.8372 17.6098 56.6947 18.1398C56.5726 18.5898 56.1655 18.8898 55.7076 18.8898Z"
        fill="#6E3264"
      />
      <path
        d="M53.2857 28.2394C53.2043 28.2394 53.1229 28.2394 53.0313 28.2094L42.7632 25.6494C42.2137 25.5094 41.888 24.9694 42.0305 24.4394C42.173 23.8994 42.7225 23.5794 43.272 23.7194L53.5401 26.2794C54.0897 26.4194 54.4153 26.9594 54.2728 27.4894C54.1507 27.9394 53.7437 28.2394 53.2857 28.2394Z"
        fill="#6E3264"
      />
      <path
        d="M50.8736 37.589C50.7922 37.589 50.7108 37.589 50.6192 37.559L40.3511 34.999C39.8016 34.859 39.4759 34.319 39.6184 33.789C39.7609 33.249 40.3104 32.929 40.8599 33.069L51.128 35.629C51.6776 35.769 52.0032 36.309 51.8607 36.839C51.7386 37.289 51.3316 37.589 50.8736 37.589Z"
        fill="#6E3264"
      />
      <path
        d="M8.53938 22.8093C8.07126 22.8093 7.64385 22.4893 7.54208 22.0193C7.41996 21.4793 7.77614 20.9493 8.32567 20.8293L14.1873 19.5793C14.7369 19.4593 15.2762 19.8093 15.3983 20.3493C15.5205 20.8893 15.1643 21.4193 14.6147 21.5393L8.75308 22.7893C8.68185 22.8093 8.61061 22.8093 8.53938 22.8093Z"
        fill="#6E3264"
      />
      <path
        d="M63.1358 9.80962C62.3013 8.42962 60.9682 7.44962 59.3806 7.04962L34.6518 0.879623C31.3749 0.059623 28.037 2.00962 27.2026 5.22962L23.3253 20.1996L23.193 19.5896C22.9691 18.5796 22.3585 17.7096 21.4732 17.1496C20.5878 16.5896 19.5295 16.3996 18.5017 16.6196L3.11479 19.9096C2.08696 20.1296 1.2016 20.7296 0.63172 21.5996C0.0618365 22.4696 -0.131517 23.5096 0.0923661 24.5196L7.79598 59.3196C8.01986 60.3296 8.63045 61.1996 9.5158 61.7596C10.1569 62.1696 10.8904 62.3799 11.6333 62.3799C11.9081 62.3799 12.1922 62.3496 12.4772 62.2896L27.864 58.9996C28.8919 58.7796 29.7772 58.1796 30.3471 57.3096C30.917 56.4396 31.1103 55.3996 30.8865 54.3896L28.3016 42.7096L48.8276 47.8296C49.3263 47.9596 49.8351 48.0196 50.3337 48.0196H50.3439C53.0712 48.0196 55.5746 46.1996 56.2768 43.4796L63.8074 14.3696C64.2145 12.8096 63.9702 11.1896 63.1358 9.80962ZM28.902 54.8196C29.0038 55.3096 28.9122 55.8196 28.6374 56.2396C28.3627 56.6596 27.9353 56.9496 27.4366 57.0596L12.0498 60.3496C11.5409 60.4496 11.0321 60.3596 10.6047 60.0896C10.1773 59.8196 9.88216 59.3996 9.77022 58.9096L2.07678 24.0996C1.97502 23.5996 2.06661 23.0996 2.34137 22.6796C2.61614 22.2596 3.04355 21.9696 3.5422 21.8596L18.9291 18.5696C19.4277 18.4796 19.9467 18.5696 20.3741 18.8396C20.8015 19.1096 21.0967 19.5296 21.2086 20.0196L23.1736 28.9199L25.6159 39.9799L26.0933 42.1596L28.8919 54.8196H28.902ZM61.8332 13.8696L54.3026 42.9796C53.7429 45.1296 51.5142 46.4396 49.3263 45.8896L27.8131 40.5296L24.2921 24.5896L29.1666 5.72962C29.7263 3.57962 31.955 2.26962 34.1429 2.81962L58.8718 8.98962C59.9302 9.24962 60.8155 9.90962 61.3752 10.8296C61.935 11.7496 62.0876 12.8296 61.823 13.8696H61.8332Z"
        fill="#6E3264"
      />
      <path
        d="M21.9829 33.5299C21.6776 33.1999 21.3414 32.8991 20.9547 32.6491C20.7105 32.4991 20.4663 32.3791 20.2017 32.2691C19.1942 31.8391 18.085 31.7391 17.0063 32.0091C15.9072 32.2791 14.9099 32.8992 14.1874 33.7491C12.956 33.1992 11.6636 33.0891 10.4933 33.4291C7.88812 34.1091 6.32094 36.8091 6.93153 39.5791L6.95188 39.6791C7.15541 40.5991 7.47088 41.2791 7.939 41.8491C9.27212 43.6891 11.399 45.0491 14.2993 45.8991C14.4316 45.9291 14.5741 45.9791 14.7267 46.0191C15.205 46.1591 15.7749 46.3291 16.3753 46.3291C16.5687 46.3291 16.7722 46.3092 16.9656 46.2691C17.9323 46.0691 18.6447 45.3391 19.2146 44.7591L19.2451 44.7191C20.6698 43.4891 21.7282 42.2591 22.4405 40.9791C22.7662 40.3991 23.0206 39.8191 23.2037 39.2191C23.2139 39.1791 23.2244 39.1399 23.2346 39.0999C23.8249 37.0299 23.316 34.9599 21.9829 33.5299ZM17.841 43.2597L17.7494 43.3596C17.3729 43.7496 16.9047 44.2297 16.5384 44.3097C16.2433 44.3797 15.7548 44.2297 15.3172 44.0997C15.1442 44.0497 14.9712 43.9996 14.8491 43.9697C12.4271 43.2597 10.6665 42.1597 9.56747 40.6397C9.26218 40.2697 9.079 39.8497 8.94671 39.2497V39.2297L8.92636 39.1297C8.59053 37.6197 9.28253 35.7896 11.0431 35.3297C12.2541 34.9697 13.3837 35.5097 13.9434 35.8697C14.1774 36.0197 14.4624 36.0697 14.7371 35.9997C15.0119 35.9397 15.246 35.7597 15.3783 35.5197C15.826 34.7297 16.5994 34.1497 17.4848 33.9297C18.248 33.7397 19.0213 33.8593 19.693 34.2393C19.7337 34.2693 19.7846 34.2793 19.8355 34.3093C21.2093 35.1793 21.7792 36.9093 21.25 38.6193C21.1482 38.9493 21.0159 39.2793 20.8531 39.6093C20.2629 40.8193 19.286 42.0097 17.841 43.2597Z"
        fill="#6E3264"
      />
      <path
        d="M30.022 34.9098C30.3069 34.9798 30.5919 35.0198 30.8768 35.0198C32.4745 35.0198 33.8585 33.9598 34.2554 32.4398C34.4895 31.5498 34.347 30.6298 33.8687 29.8498C33.3904 29.0598 32.6374 28.5098 31.7317 28.2798C31.4467 28.2098 31.1618 28.1698 30.8768 28.1698C29.2791 28.1698 27.8951 29.2298 27.4982 30.7498C27.0199 32.5798 28.1597 34.4498 30.022 34.9098ZM29.4728 31.2399C29.6356 30.5999 30.2258 30.1699 30.8771 30.1699C30.9992 30.1699 31.1112 30.1799 31.2333 30.2099C32.0067 30.3999 32.4748 31.1799 32.2815 31.9399C32.1187 32.5799 31.5284 33.0099 30.8771 33.0099C30.755 33.0099 30.6431 32.9999 30.521 32.9699C29.7475 32.7799 29.2794 31.9999 29.4728 31.2399Z"
        fill="#6E3264"
      />
      <path
        d="M33.299 20.8193C33.4212 20.8193 33.5331 20.8293 33.6552 20.8593C34.4286 21.0493 34.8967 21.8293 34.7034 22.5893C34.5406 23.2293 33.9503 23.6593 33.299 23.6593C33.1769 23.6593 33.065 23.6493 32.9429 23.6193C32.1694 23.4293 31.7013 22.6493 31.8947 21.8893C32.0575 21.2493 32.6477 20.8193 33.299 20.8193ZM33.299 18.8193C31.7013 18.8193 30.3173 19.8793 29.9204 21.3993C29.6864 22.2893 29.8288 23.2093 30.3071 23.9893C30.7854 24.7793 31.5385 25.3293 32.4442 25.5593C32.7291 25.6293 33.0141 25.6693 33.299 25.6693C34.8967 25.6693 36.2807 24.6093 36.6776 23.0893C37.1559 21.2593 36.0162 19.3893 34.1539 18.9293C33.8689 18.8593 33.584 18.8193 33.299 18.8193Z"
        fill="#6E3264"
      />
      <path
        d="M35.7109 11.4697C35.833 11.4697 35.9449 11.4797 36.067 11.5097C36.8405 11.6997 37.3086 12.4797 37.1152 13.2397C36.9524 13.8797 36.3622 14.3097 35.7109 14.3097C35.5887 14.3097 35.4768 14.2997 35.3547 14.2697C34.5813 14.0797 34.1132 13.2997 34.3065 12.5397C34.4693 11.8997 35.0596 11.4697 35.7109 11.4697ZM35.7109 9.46973C34.1132 9.46973 32.7292 10.5297 32.3323 12.0497C31.854 13.8797 32.9937 15.7497 34.856 16.2097C35.141 16.2797 35.4259 16.3197 35.7109 16.3197C37.3086 16.3197 38.6926 15.2597 39.0895 13.7397C39.3235 12.8497 39.181 11.9297 38.7028 11.1497C38.2245 10.3597 37.4714 9.80973 36.5657 9.57973C36.2807 9.50973 35.9958 9.46973 35.7109 9.46973Z"
        fill="#6E3264"
      />
      <path
        clipRule="evenodd"
        d="M37.7867 60.3096C38.5703 60.4696 39.364 60.5496 40.1476 60.5496V60.5296C41.8471 60.5296 43.5466 60.1496 45.1341 59.3996C47.8919 58.0896 49.9679 55.7996 50.9754 52.9496C51.1586 52.4296 50.8737 51.8596 50.3445 51.6796C49.8153 51.4996 49.2352 51.7796 49.0521 52.2996C48.2278 54.6396 46.5181 56.5296 44.2488 57.6096C41.9794 58.6996 39.4149 58.8396 37.0336 58.0296C37.0031 58.0196 36.9726 58.0096 36.942 57.9996L39.8016 55.1796C40.1985 54.7896 40.1985 54.1596 39.8016 53.7696C39.4047 53.3796 38.7636 53.3796 38.3667 53.7696L34.0315 58.0396C33.6347 58.4296 33.6347 59.0596 34.0315 59.4496L38.3769 63.7096C38.5804 63.8996 38.8349 63.9996 39.0994 63.9996C39.364 63.9996 39.6185 63.9096 39.822 63.7096C40.2189 63.3196 40.2189 62.6896 39.822 62.2996L37.7867 60.3096Z"
        fill="#6E3264"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_410_1724"
        x1="16.6604"
        x2="42.9424"
        y1="61.4507"
        y2="1.3359"
      >
        <stop offset="0.08" stopColor="#F6D2FB" />
        <stop offset="0.77" stopColor="white" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_410_1724"
        x1="31.9896"
        x2="31.9988"
        y1="72.4062"
        y2="0.684963"
      >
        <stop stopColor="#5C2F57" />
        <stop offset="1" stopColor="#A4D4FB" />
      </linearGradient>
      <clipPath id="clip0_410_1724">
        <rect fill="white" height="64" width="64" />
      </clipPath>
    </defs>
  </svg>
)

export default TransferReg
