import classNames from 'classnames'
import React from 'react'
import css from './Meter.styles.scss'

interface MeterProps {
  percentage: number
}

const Meter: React.FC<MeterProps> = ({ percentage }) => {
  // radius value comes from the svg's already defined radius/size
  const radius = 36
  const halfCircumference = radius * Math.PI

  const fill = halfCircumference * percentage
  const empty = halfCircumference * (2 - percentage)

  return (
    <svg
      aria-hidden
      fill="none"
      height="40"
      viewBox="0 0 80 40"
      width="80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        height="40"
        id="mask0_9125_473"
        maskUnits="userSpaceOnUse"
        width="80"
        x="0"
        y="0"
      >
        <path
          clipRule="evenodd"
          d="M58.3466 4.41275C57.3774 3.91761 56.3868 3.46114 55.3771 3.04482C50.502 1.03463 45.2768 0 40 0C34.7232 0 29.498 1.03463 24.6228 3.04482C23.6274 3.45528 22.6505 3.90478 21.6943 4.39188L25.719 11.3312C26.3675 11.0111 27.0276 10.7124 27.6983 10.4359C31.5984 8.8277 35.7785 8 40 8C44.2215 8 48.4016 8.8277 52.3017 10.4359C52.9866 10.7182 53.6604 11.0237 54.3222 11.3516L58.3466 4.41275ZM60.1111 5.37042L56.0928 12.2986C58.5129 13.6918 60.7453 15.3963 62.7306 17.3726C65.7156 20.3441 68.0835 23.8717 69.699 27.7541C70.8006 30.4017 71.5377 33.1789 71.8951 36.0104C72.1718 38.2023 73.945 40 76.1642 40C78.3834 40 80.2032 38.2047 79.9817 36.0066C79.5903 32.1237 78.6294 28.3113 77.1237 24.6927C75.1044 19.8396 72.1445 15.4301 68.4132 11.7157C65.9303 9.24403 63.1381 7.1124 60.1111 5.37042ZM11.5867 11.7157C14.0806 9.23321 16.8864 7.09375 19.9287 5.34756L23.9469 12.2758C21.5115 13.6731 19.2655 15.3855 17.2694 17.3726C14.2843 20.3441 11.9165 23.8717 10.301 27.7541C9.19935 30.4017 8.46228 33.1789 8.10486 36.0104C7.82818 38.2023 6.05502 40 3.8358 40C1.61659 40 -0.203249 38.2047 0.0183055 36.0066C0.409683 32.1237 1.37054 28.3113 2.87626 24.6927C4.89562 19.8396 7.85544 15.4301 11.5867 11.7157Z"
          fill="#DFDDDE"
          fillRule="evenodd"
        />
      </mask>
      <path
        clipRule="evenodd"
        d="M58.3466 4.41275C57.3774 3.91761 56.3868 3.46114 55.3771 3.04482C50.502 1.03463 45.2768 0 40 0C34.7232 0 29.498 1.03463 24.6228 3.04482C23.6274 3.45528 22.6505 3.90478 21.6943 4.39188L25.719 11.3312C26.3675 11.0111 27.0276 10.7124 27.6983 10.4359C31.5984 8.8277 35.7785 8 40 8C44.2215 8 48.4016 8.8277 52.3017 10.4359C52.9866 10.7182 53.6604 11.0237 54.3222 11.3516L58.3466 4.41275ZM60.1111 5.37042L56.0928 12.2986C58.5129 13.6918 60.7453 15.3963 62.7306 17.3726C65.7156 20.3441 68.0835 23.8717 69.699 27.7541C70.8006 30.4017 71.5377 33.1789 71.8951 36.0104C72.1718 38.2023 73.945 40 76.1642 40C78.3834 40 80.2032 38.2047 79.9817 36.0066C79.5903 32.1237 78.6294 28.3113 77.1237 24.6927C75.1044 19.8396 72.1445 15.4301 68.4132 11.7157C65.9303 9.24403 63.1381 7.1124 60.1111 5.37042ZM11.5867 11.7157C14.0806 9.23321 16.8864 7.09375 19.9287 5.34756L23.9469 12.2758C21.5115 13.6731 19.2655 15.3855 17.2694 17.3726C14.2843 20.3441 11.9165 23.8717 10.301 27.7541C9.19935 30.4017 8.46228 33.1789 8.10486 36.0104C7.82818 38.2023 6.05502 40 3.8358 40C1.61659 40 -0.203249 38.2047 0.0183055 36.0066C0.409683 32.1237 1.37054 28.3113 2.87626 24.6927C4.89562 19.8396 7.85544 15.4301 11.5867 11.7157Z"
        fill="#DFDDDE"
        fillRule="evenodd"
      />
      <g mask="url(#mask0_9125_473)">
        <circle
          className={classNames(
            css.meter,
            // at 0, the bar still draws at the start so needs to be hidden
            percentage === 0 && css.meter__hidden
          )}
          cx="40"
          cy="40"
          r={radius}
          stroke="url(#paint0_linear_9125_473)"
          strokeDasharray={`${fill} ${empty}`}
          strokeDashoffset={-halfCircumference}
          strokeLinecap="round"
          strokeWidth="8.5"
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_9125_473"
          x1="-0.181641"
          x2="80.1832"
          y1="40"
          y2="40"
        >
          <stop stopColor="#6BC9FF" />
          <stop offset="0.9" stopColor="#C176B4" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Meter
