import { useMemo } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Battery, HandsFree, Plug } from 'baby-design/icons'
import styles from './PumpType.styles.scss'

const PumpType = ({ pumpType }: Pick<APIResponse.PopularPumps, 'pumpType'>) => {
  const pumpTypesMap: {
    [key: string]: { label: string; icon: JSX.Element }
  } = {
    Wearable: {
      label: 'Wearable',
      icon: <HandsFree data-testid="handsFree-icon" />,
    },
    Portable: {
      label: 'Portable',
      icon: <Battery data-testid="battery-icon" />,
    },
    Corded: { label: 'Corded', icon: <Plug data-testid="plug-icon" /> },
  }

  const pumpTypeDetails = useMemo(() => {
    if (pumpType.includes('Cordless') && pumpType.includes('Wearable')) {
      return pumpTypesMap.Wearable
    }
    if (pumpType.includes('Cordless')) {
      return pumpTypesMap.Portable
    }
    return pumpTypesMap.Corded
  }, [
    pumpType,
    pumpTypesMap.Corded,
    pumpTypesMap.Portable,
    pumpTypesMap.Wearable,
  ])

  return (
    <div className={styles.PumpType}>
      <div aria-hidden="true" className={styles.pump_icon}>
        {pumpTypeDetails.icon}
      </div>
      <div className={styles.pump_type}>{pumpTypeDetails.label}</div>
    </div>
  )
}

export default PumpType
