import React from 'react'

const HandsFree: React.FC<React.HTMLAttributes<SVGElement>> = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Style=Fill">
      <path
        clipRule="evenodd"
        d="M12.5 7C13.8807 7 15 5.88071 15 4.5C15 3.11929 13.8807 2 12.5 2C11.1193 2 10 3.11929 10 4.5C10 5.36767 10.442 6.1321 11.1132 6.5804C8.81162 6.31244 6.32876 7.92806 5.49994 10.0001C4.49991 12.5001 4.8463 14.0001 5.75329 14.0001C6.32482 14.0001 6.44387 13.3619 6.59692 12.5413C6.68676 12.0596 6.78831 11.5152 6.99994 11.0001C7.49994 9.78317 8.80723 9.0274 9.30723 8.82643C9.22532 9.38349 9.1447 9.89069 9.06985 10.3617C8.84472 11.7781 8.67165 12.867 8.67165 14.0001C8.67165 14.8874 9.91725 16.0132 10.6334 16.6604C10.7849 16.7973 10.9126 16.9128 10.9999 17.0001C11.1842 17.1843 11.3482 17.3268 11.5062 17.4641C11.777 17.6994 12.0302 17.9194 12.3375 18.308C12.5018 18.5158 12.5552 19.1183 12.6178 19.8252C12.7405 21.2118 12.8989 23.0001 13.9999 23.0001C15.3406 23.0001 14.8889 19.0001 14.4999 17.6926C14.111 16.3851 12.3375 14.0717 12.3375 14.0717L12.9999 10.8466C13.9999 12.1296 17.4999 15.0001 17.9999 13.5001C18.2847 12.6459 17.2879 12.0264 16.2823 11.4014L16.2823 11.4014C15.8139 11.1102 15.3435 10.8179 14.9999 10.5001C14.5128 10.0495 14.192 9.41549 13.8807 8.80024C13.5011 8.04997 13.1357 7.32765 12.5 7.00008C12.4999 7.00005 12.4998 7.00003 12.4998 7C12.4999 7 12.4999 7 12.5 7ZM7.42572 19.7225L7.42574 19.7224L7.42575 19.7224C7.64972 19.4577 7.85753 19.2121 7.99994 19.0001C8.67165 18.0001 8.99994 17.5001 8.99994 16.0001C9.52439 16.6973 10.0467 17.1515 10.4844 17.532L10.4844 17.532C10.6744 17.6973 10.8486 17.8487 10.9999 18.0001C10.7854 18.4409 7.49994 23.5001 6.49994 22.5001C5.72839 21.7285 6.66925 20.6165 7.42572 19.7225Z"
        fill="black"
        fillRule="evenodd"
        id="Union"
      />
    </g>
  </svg>
)

export default HandsFree
