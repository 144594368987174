import { FC } from 'react'

// eslint-disable-next-line import/no-extraneous-dependencies
import { Button, TextLink } from 'baby-design'
import { DME_PARTS_AND_BAGS_TERMS_URL } from 'bl-health/constants'
import { FirstViewWaypoint } from 'bl-health/Homepage/components/FirstViewWaypoint/FirstViewWaypoint'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import styles from './YouGotThisSection.styles.scss'
import Sticker from './components/Sticker/Sticker'
import ListItem from './components/ListItem/ListItem'
import { INSURANCE_PICKER_SECTION_ID } from '../InsurancePickerSection/InsurancePickerSection'

const YOU_GOT_THIS_CLOVER_IMAGE_URL =
  'https://images.babylist.com/image/upload/v1731698818/DME/you_got_this_section_clover.png'

const YouGotThisSection: FC = () => {
  const tracker = useTracking()

  const onView = () => {
    tracker.trackEvent({
      event: track.homepageSectionViewed,
      healthSection: 'You got this',
    })
  }

  const onClick = () => {
    tracker.trackEvent({
      event: track.homepageCtaClicked,
      healthSection: 'You got this',
    })
  }

  return (
    <FirstViewWaypoint onView={onView}>
      <div className={styles.YouGotThisSection}>
        <div className={styles.grid_layout}>
          <div className={styles.graphic_container}>
            <div className={styles.graphic}>
              <div className={styles.sticker}>
                <Sticker>
                  <h3 className={styles.sticker_text}>You got this!</h3>
                </Sticker>
              </div>
              <img
                alt="Affirmation"
                className={styles.affirmation_image}
                src={YOU_GOT_THIS_CLOVER_IMAGE_URL}
              />
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.list_items}>
              <ListItem>We coordinate with your insurance and doctors</ListItem>
              <ListItem>
                Free pump options for every accepted insurance plan
              </ListItem>
              <ListItem>Free* milk storage bags and replacement parts</ListItem>
            </div>
            <div className={styles.button_container}>
              <Button
                href={`#${INSURANCE_PICKER_SECTION_ID}`}
                size="md"
                variant="primary"
                onClick={onClick}
              >
                Find your pump
              </Button>
              <div className={styles.exclusions_apply}>
                *Exclusions apply, offers may vary by insurance provider.{' '}
                <TextLink
                  underline
                  className={styles.details_terms}
                  href={DME_PARTS_AND_BAGS_TERMS_URL}
                  rel="noopener noreferrer"
                  size="sm"
                  target="_blank"
                  weight="regular"
                >
                  See details/terms.
                </TextLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FirstViewWaypoint>
  )
}

export default YouGotThisSection
