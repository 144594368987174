import { Factory } from 'fishery'
import faker from 'faker'
import { CartItem } from 'src/types/cart'

export default Factory.define<CartItem>(({ transientParams, sequence }) => {
  const { withRegItem } = transientParams

  return {
    uuid: faker.datatype.uuid(),
    regItem: withRegItem ? { id: sequence } : undefined,
    quantity: faker.datatype.number(),
    quantityLimit: faker.datatype.number(),
    name: faker.commerce.productName(),
    imageUrl: faker.image.imageUrl(),
    price: Number(faker.commerce.price()),
    productId: faker.datatype.number(),
    canPurchaseWithUserCredit: faker.datatype.boolean(),
    fsaHsaEligible: faker.datatype.boolean(),
    hasShippingSurcharge: faker.datatype.boolean(),
    isElectronicGiftCard: faker.datatype.boolean(),
    isEligibleForFreeShipping: faker.datatype.boolean(),
    isEligibleForRegistryDiscount: faker.datatype.boolean(),
    isGiftCard: faker.datatype.boolean(),
    isTaxable: faker.datatype.boolean(),
    isOutOfStock: faker.datatype.boolean(),
  }
})
