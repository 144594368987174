import { track, Tracker } from 'lib/babylistHealthAnalytics'
import { STORE_NAME } from 'bl-health/constants'
import { decorateEventPayload } from './decorateEventPayload'

export interface GuideClickedMetadata {
  eventCta?: string
  healthVersion?: string
  postId?: string
  postRevision?: number
  postSlug?: string
}

export const guideClickedEvent =
  (tracker: Tracker) =>
  ({
    eventCta,
    healthVersion,
    postId,
    postRevision,
    postSlug,
  }: GuideClickedMetadata) => {
    const payload = decorateEventPayload({
      event: track.guideClicked,
      eventCta,
      healthVersion,
      postId,
      postRevision,
      postSlug,
      postType: 'Guide',
      storeName: STORE_NAME,
    })
    tracker.trackEvent(payload)
  }
