const Sticker: React.FC<React.HTMLAttributes<SVGElement>> = () => (
  <svg
    fill="none"
    height="144"
    viewBox="0 0 144 144"
    width="144"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask fill="white" id="path-1-inside-1_7333_22142">
      <path d="M97.1262 132.072C90.6983 128.553 82.6301 130.22 78.1704 136.004L76.9824 137.54C70.253 146.263 56.5814 144.842 51.8203 134.933L50.7741 132.766C47.8483 126.684 41.1189 123.349 34.4427 124.691L32.0222 125.174C21.6843 127.245 12.5345 118.276 14.5205 108.026L15.4426 103.26C16.6129 97.2309 13.8644 91.1227 8.56245 87.9458L7.14387 87.0945C-1.88185 81.6796 -2.46702 68.9367 6.02673 62.7407L9.89237 59.9236C15.2741 55.9919 17.331 48.9885 14.9195 42.8189L14.0506 40.6073C10.2736 30.9448 17.5261 20.5538 27.9881 20.6416L33.6713 20.6854C39.8954 20.7293 45.4899 16.9293 47.6709 11.1546L48.2738 9.54852C51.9356 -0.10523 64.2063 -3.133 71.9996 3.69484L74.4821 5.87132C78.8886 9.73282 85.1924 10.6455 90.5387 8.197L95.3441 5.99419C104.512 1.79042 115.151 7.69676 116.304 17.6401L116.871 22.5548C117.598 28.8122 122.271 33.9111 128.495 35.2275L131.261 35.8155C141.696 38.0271 146.422 50.1031 140.216 58.6949L138.478 61.1083C134.559 66.5408 134.834 73.9127 139.152 79.038L139.852 79.8717C146.998 88.3495 142.476 101.303 131.571 103.62L130.826 103.778C123.893 105.244 118.981 111.378 119.105 118.39V118.987C119.327 130.282 107.101 137.566 97.1174 132.09L97.1262 132.072Z" />
    </mask>
    <path
      d="M97.1262 132.072C90.6983 128.553 82.6301 130.22 78.1704 136.004L76.9824 137.54C70.253 146.263 56.5814 144.842 51.8203 134.933L50.7741 132.766C47.8483 126.684 41.1189 123.349 34.4427 124.691L32.0222 125.174C21.6843 127.245 12.5345 118.276 14.5205 108.026L15.4426 103.26C16.6129 97.2309 13.8644 91.1227 8.56245 87.9458L7.14387 87.0945C-1.88185 81.6796 -2.46702 68.9367 6.02673 62.7407L9.89237 59.9236C15.2741 55.9919 17.331 48.9885 14.9195 42.8189L14.0506 40.6073C10.2736 30.9448 17.5261 20.5538 27.9881 20.6416L33.6713 20.6854C39.8954 20.7293 45.4899 16.9293 47.6709 11.1546L48.2738 9.54852C51.9356 -0.10523 64.2063 -3.133 71.9996 3.69484L74.4821 5.87132C78.8886 9.73282 85.1924 10.6455 90.5387 8.197L95.3441 5.99419C104.512 1.79042 115.151 7.69676 116.304 17.6401L116.871 22.5548C117.598 28.8122 122.271 33.9111 128.495 35.2275L131.261 35.8155C141.696 38.0271 146.422 50.1031 140.216 58.6949L138.478 61.1083C134.559 66.5408 134.834 73.9127 139.152 79.038L139.852 79.8717C146.998 88.3495 142.476 101.303 131.571 103.62L130.826 103.778C123.893 105.244 118.981 111.378 119.105 118.39V118.987C119.327 130.282 107.101 137.566 97.1174 132.09L97.1262 132.072Z"
      fill="#FF5D38"
      mask="url(#path-1-inside-1_7333_22142)"
      stroke="url(#paint0_linear_7333_22142)"
      strokeWidth="2"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_7333_22142"
        x1="71.6524"
        x2="71.6524"
        y1="0"
        y2="143.305"
      >
        <stop stopColor="#FFB8A8" />
        <stop offset="1" stopColor="#D199C8" />
      </linearGradient>
    </defs>
  </svg>
)

export default Sticker
