import { FC } from 'react'

import GuidesSection from './sections/GuidesSection/GuidesSection'
import FAQSection from './sections/FAQSection/FAQSection'
import HereToHelpSection from './sections/HereToHelpSection/HereToHelpSection'
import HorizontalDivider from './components/HorizontalDivider/HorizontalDivider'
import HowBLHWorksSection from './sections/HowBLHWorksSection/HowBLHWorksSection'
import InsurancePickerSection from './sections/InsurancePickerSection/InsurancePickerSection'
import LegacyAppBar from './legacy_components/LegacyAppBar'
import LegacyFooter from './legacy_components/LegacyFooter'
import PopularPumpsSection from './sections/PopularPumpsSection/PopularPumpsSection'
import TestimonialsSection from './sections/TestimonialsSection/TestimonialsSection'
import WaveDivider from './components/WaveDivider/WaveDivider'
import YouGotThisSection from './sections/YouGotThisSection/YouGotThisSection'

const Homepage: FC = () => (
  <>
    <LegacyAppBar />
    <InsurancePickerSection />
    <PopularPumpsSection />
    <HowBLHWorksSection />
    <YouGotThisSection />
    <HorizontalDivider />
    <TestimonialsSection />
    <HereToHelpSection />
    <FAQSection />
    <WaveDivider />
    <GuidesSection />
    <LegacyFooter />
  </>
)

export default Homepage
