import { track, Tracker } from 'lib/babylistHealthAnalytics'
import { decorateEventPayload } from './decorateEventPayload'

export interface FreeReplacementPartsClickedMetadata {
  healthSection: string
}

export const freeReplacementPartsClickedEvent =
  (tracker: Tracker) =>
  ({ healthSection }: FreeReplacementPartsClickedMetadata) => {
    const payload = decorateEventPayload({
      event: track.freeReplacementPartsClicked,
      healthSection,
    })
    tracker.trackEvent(payload)
  }
