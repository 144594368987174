import { FC } from 'react'
import AppBar from 'baby-ui/compounds/AppBar'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import LegacyMobXWrapper from './LegacyMobXWrapper'
import LegacyMuiWrapper from './LegacyMuiWrapper'
import { FirstViewWaypoint } from '../components/FirstViewWaypoint/FirstViewWaypoint'

const LegacyAppBar: FC = () => {
  const { flagValue: milkBagsForPartsBuyers } = useFeatureFlag(
    'dme_milk_bags_for_parts_buyers',
    false
  )

  const tracker = useTracking()

  const onView = () => {
    tracker.trackEvent({
      event: track.homepageSectionViewed,
      healthSection: 'Health navigation bar',
    })
  }

  return (
    <FirstViewWaypoint onView={onView}>
      <LegacyMobXWrapper>
        <LegacyMuiWrapper>
          <AppBar
            isHealthRedesign
            milkBagsForPartsBuyersOverride={milkBagsForPartsBuyers ?? false}
          />
        </LegacyMuiWrapper>
      </LegacyMobXWrapper>
    </FirstViewWaypoint>
  )
}

export default LegacyAppBar
