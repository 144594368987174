import React from 'react'

interface PlugProps extends React.HTMLAttributes<SVGElement> {
  filled?: boolean
}

const Plug: React.FC<PlugProps> = ({ filled = false }) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {filled ? (
      <g id="style=Fill">
        <path
          clipRule="evenodd"
          d="M16 3.25C16.4142 3.25 16.75 3.58579 16.75 4V7.25H20C20.4142 7.25 20.75 7.58579 20.75 8C20.75 8.41421 20.4142 8.75 20 8.75H16.75V13.25H20C20.4142 13.25 20.75 13.5858 20.75 14C20.75 14.4142 20.4142 14.75 20 14.75H16.75V18C16.75 18.4142 16.4142 18.75 16 18.75C15.5858 18.75 15.25 18.4142 15.25 18V16.75H12C9.37665 16.75 7.25 14.6234 7.25 12V11.837C5.81665 12.1755 4.75 13.4632 4.75 15V21C4.75 21.4142 4.41421 21.75 4 21.75C3.58579 21.75 3.25 21.4142 3.25 21V15C3.25 12.6318 4.98301 10.6685 7.25 10.3089V10C7.25 7.37665 9.37665 5.25 12 5.25H15.25V4C15.25 3.58579 15.5858 3.25 16 3.25Z"
          fill="black"
          fillRule="evenodd"
          id="Union"
        />
      </g>
    ) : (
      <g id="style=Outline">
        <path
          clipRule="evenodd"
          d="M16 3.25C16.4142 3.25 16.75 3.58579 16.75 4V7.25H20C20.4142 7.25 20.75 7.58579 20.75 8C20.75 8.41421 20.4142 8.75 20 8.75H16.75V13.25H20C20.4142 13.25 20.75 13.5858 20.75 14C20.75 14.4142 20.4142 14.75 20 14.75H16.75V18C16.75 18.4142 16.4142 18.75 16 18.75C15.5858 18.75 15.25 18.4142 15.25 18V16.75H12C9.37665 16.75 7.25 14.6234 7.25 12V11.837C5.81665 12.1755 4.75 13.4632 4.75 15V21C4.75 21.4142 4.41421 21.75 4 21.75C3.58579 21.75 3.25 21.4142 3.25 21V15C3.25 12.6318 4.98301 10.6685 7.25 10.3089V10C7.25 7.37665 9.37665 5.25 12 5.25H15.25V4C15.25 3.58579 15.5858 3.25 16 3.25ZM15.25 6.75L15.25 15.25H12C10.2051 15.25 8.75 13.7949 8.75 12V10C8.75 8.20507 10.2051 6.75 12 6.75H15.25Z"
          fill="black"
          fillRule="evenodd"
          id="Union"
        />
      </g>
    )}
  </svg>
)

export default Plug
