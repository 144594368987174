import { FC } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { LinkOut } from 'baby-design/icons'
import styles from './DetailCard.styles.scss'

interface DetailCardProps {
  title: string
  linkTitle: string
  imageUrl: string
  guideUrl: string
  onGuideClick: () => void
}

const DetailCard: FC<DetailCardProps> = ({
  title,
  linkTitle,
  imageUrl,
  guideUrl,
  onGuideClick,
}) => (
  <a
    aria-label={title}
    className={styles.DetailCard}
    href={guideUrl}
    rel="noreferrer"
    target="_blank"
    title={title}
    onClick={onGuideClick}
  >
    <img alt={title} className={styles.image} src={imageUrl} />
    <div className={styles.content}>
      {/* Spacing at end necessary for SEO */}
      <h4 className={styles.title}>{title} </h4>
      <div className={styles.cta_container}>
        <div className={styles.cta}>{linkTitle}</div>
        <LinkOut className={styles.cta_icon} />
      </div>
    </div>
  </a>
)
export default DetailCard
