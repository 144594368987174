import { QueryClientProvider, Hydrate, dehydrate } from '@tanstack/react-query'
import { FC, StrictMode } from 'react'
import { queryClient } from 'src/queryClient'
import NiceModal from '@ebay/nice-modal-react'
import { withContextualizedTracking } from 'lib/babylistHealthAnalytics'
import Homepage from './Homepage'

interface AppProps {
  hydrationData?: Array<{ key: Array<string | number>; data: unknown }>
}
const HomepageApp: FC<AppProps> = ({ hydrationData = [] }) => {
  hydrationData.forEach(({ key, data }) => {
    queryClient.setQueryData(key, data)
  })

  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={dehydrate(queryClient)}>
          <NiceModal.Provider>
            <Homepage />
          </NiceModal.Provider>
        </Hydrate>
      </QueryClientProvider>
    </StrictMode>
  )
}

export default withContextualizedTracking()(({ hydrationData = [] }) => (
  <HomepageApp hydrationData={hydrationData} />
))
