import { useState } from 'react'
import { Waypoint } from 'react-waypoint'

interface FirstViewWaypointProps {
  onView: () => void
  children: React.ReactNode
}

export const FirstViewWaypoint: React.FC<FirstViewWaypointProps> = ({
  onView,
  children,
}) => {
  const [hasBeenViewed, setHasBeenViewed] = useState(false)

  const onEnter = () => {
    if (!hasBeenViewed) {
      setHasBeenViewed(true)
      onView()
    }
  }

  return (
    <Waypoint onEnter={onEnter}>
      <div>{children}</div>
    </Waypoint>
  )
}
