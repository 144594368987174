import { FC } from 'react'
import { useQuery } from '@tanstack/react-query'
import getGuides from 'bl-health/requests/getGuides'
import { FirstViewWaypoint } from 'bl-health/Homepage/components/FirstViewWaypoint/FirstViewWaypoint'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import { guideClickedEvent } from 'bl-health/events'
import styles from './GuidesSection.styles.scss'
import DetailCard from './components/DetailCard/DetailCard'

const GuidesSection: FC = () => {
  const { data: guides } = useQuery<APIResponse.Guides[]>({
    queryKey: ['guides'],
    queryFn: getGuides,
  })
  const tracker = useTracking()

  const onView = () => {
    tracker.trackEvent({
      event: track.homepageSectionViewed,
      healthSection: 'Guides',
    })
  }

  return (
    <FirstViewWaypoint onView={onView}>
      <div className={styles.GuidesSection}>
        <div className={styles.grid_layout}>
          <h2 className={styles.section_title}>
            Not sure where to start? Check out our expert guides
          </h2>
          <div className={styles.detail_cards}>
            {guides?.map((guide: APIResponse.Guides) => (
              <DetailCard
                guideUrl={guide.postUrl}
                imageUrl={guide.imageUrl}
                key={guide.id}
                linkTitle={guide.ctaText}
                title={guide.title}
                onGuideClick={() =>
                  guideClickedEvent(tracker)({
                    eventCta: guide.title,
                    healthVersion: 'v2',
                    postId: guide.id,
                    postSlug: guide.postUrl,
                    postRevision: 0,
                  })
                }
              />
            ))}
          </div>
        </div>
      </div>
    </FirstViewWaypoint>
  )
}

export default GuidesSection
