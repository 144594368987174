import { Factory } from 'fishery'
import faker from 'faker'

import {
  GiftGiver,
  ThankYouNoteAddress,
} from 'src/routes/(registry)/registry/(gift-tracker-revamp)/context/GiftGiversProvider.types'

import reservationFactory from './reservationFactory'

const thankYouNoteAddressFactory = Factory.define<ThankYouNoteAddress>(() => ({
  name: faker.name.findName(),
  address1: faker.address.streetAddress(),
  address2: faker.address.secondaryAddress(),
  city: faker.address.city(),
  state: faker.address.state(),
  zip: faker.address.zipCode(),
}))

type GiftGiverTransientParams = {
  numReservations?: number
}

const giftGiverFactory = Factory.define<GiftGiver, GiftGiverTransientParams>(
  ({ transientParams }) => ({
    id: faker.datatype.number().toString(),
    name: faker.name.findName(),
    email: faker.internet.email(),
    mostRecentReservationAt: faker.date.recent().toISOString(),
    giftMessage: faker.lorem.paragraph(2),
    reservationCount: faker.datatype.number({ min: 0, max: 5 }),
    reservations: reservationFactory.buildList(
      transientParams.numReservations || 3
    ),
    thankYouNoteAddress: thankYouNoteAddressFactory.build(),
  })
)

export default giftGiverFactory
