import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      backgroundColor: 'white',
    },
    babylistMenu: {
      position: 'absolute',
      bottom: '0',
      paddingBottom: '0',
    },
    cartIcon: {
      marginLeft: theme.spacing(2),
    },
    closeButton: {
      marginBottom: theme.spacing(2),
      '&:hover': {
        background: 'none',
      },
    },
    list: {
      width: '297px',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    title: {
      textAlign: 'center',
    },
    rightGrid: {
      textAlign: 'right',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
    },
    linkButton: {
      display: 'none',
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'inline-block',
      },
      '&:hover': {
        background: 'none',
      },
    },
    menuHeader: {
      display: 'grid',
      gridTemplateColumns: '20% auto 20%',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
    },
    listItemLink: {
      color: theme.palette.text.primary,
    },
    menuDivider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    phoneSupport: {
      fontSize: '16px',
      color: theme.palette.text.primary,
    },
    hiddenSm: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      display: 'flex',
      paddingLeft: theme.spacing(2),
    },
    hiddenMd: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    imageMenuContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    listItemExternalIcon: {
      marginLeft: '2px',
      marginBottom: '-8px',
      '&:hover path, path': {
        fill: theme.palette.text.primary,
      },
    },
    healthRedesignLinkButton: {
      color: '#904183',
    },
  })
)
