import { FC } from 'react'
import styles from './HorizontalDivider.styles.scss'

const HorizontalDivider: FC = () => (
  <div className={styles.HorizontalDivider}>
    <div className={styles.grid_layout}>
      <div className={styles.horizontal_divider} />
    </div>
  </div>
)

export default HorizontalDivider
