import { useQuery } from '@tanstack/react-query'
import { getInsuranceCatalog } from 'bl-health/requests'
import { REACT_QUERY_KEY_GET_INSURANCE_CATALOG } from '../constants'

export type InsuranceEligibilityResponseType =
  | InsuranceEligibilitySuccessResponse
  | InsuranceEligibilityFailureResponse

// eslint-disable-next-line no-shadow
export enum InsuranceEligibilityResponseStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

// eslint-disable-next-line no-shadow
export enum InsuranceEligibilityFailureReasons {
  STATE_NOT_FOUND = 'stateNotFound',
  INSURANCE_NOT_FOUND = 'insuranceNotFound',
  STATE_NOT_SUPPORTED = 'stateNotSupported',
  INSURANCE_NOT_SUPPORTED = 'insuranceNotSupported',
}

interface InsuranceEligibilityFailureResponse {
  status: InsuranceEligibilityResponseStatus.FAILURE
  errors: InsuranceEligibilityFailureReasons[]
}

interface InsuranceEligibilitySuccessResponse {
  status: InsuranceEligibilityResponseStatus.SUCCESS
  stateId: number
  insuranceId: number
}

interface determineEligibilityStatusParams {
  stateId: number | null
  insuranceId: number | null
}

export default function useInsuranceEligibilityStatus() {
  const { data: insuranceCatalog } = useQuery({
    queryKey: REACT_QUERY_KEY_GET_INSURANCE_CATALOG,
    queryFn: getInsuranceCatalog,
  })

  function determineInsuranceEligibility({
    stateId,
    insuranceId,
  }: determineEligibilityStatusParams): InsuranceEligibilityResponseType {
    const selectedState = insuranceCatalog?.find(
      (state) => state.id === stateId
    )
    const selectedInsurance = selectedState?.insurances?.find(
      (insurance) => insurance.id === insuranceId
    )

    const errors = []

    if (!selectedState) {
      errors.push(InsuranceEligibilityFailureReasons.STATE_NOT_FOUND)
    } else if (!selectedState.enabled) {
      errors.push(InsuranceEligibilityFailureReasons.STATE_NOT_SUPPORTED)
    }

    if (!selectedInsurance) {
      errors.push(InsuranceEligibilityFailureReasons.INSURANCE_NOT_FOUND)
    } else if (!selectedInsurance.enabled || !selectedInsurance.eligible) {
      errors.push(InsuranceEligibilityFailureReasons.INSURANCE_NOT_SUPPORTED)
    }

    if (errors.length > 0) {
      return {
        status: InsuranceEligibilityResponseStatus.FAILURE,
        errors,
      }
    }

    return {
      status: InsuranceEligibilityResponseStatus.SUCCESS,
      stateId: stateId as number,
      insuranceId: insuranceId as number,
    }
  }

  return determineInsuranceEligibility
}
