import styles from './ReviewCard.styles.scss'

interface ReviewCardProps {
  quote: string
  author: string
}

const ReviewCard: React.FC<ReviewCardProps> = ({ quote, author }) => (
  <div className={styles.ReviewCard}>
    <div className={styles.inner_card}>
      <div>"{quote}"</div>
      <div className={styles.author}>{author}</div>
    </div>
  </div>
)

export default ReviewCard
