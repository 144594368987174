import { FC } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import Accordion from 'baby-design/components/Accordion/Accordion'
import { FirstViewWaypoint } from 'bl-health/Homepage/components/FirstViewWaypoint/FirstViewWaypoint'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import styles from './FAQSection.styles.scss'

const FAQSection: FC = () => {
  const tracker = useTracking()

  const onView = () => {
    tracker.trackEvent({
      event: track.homepageSectionViewed,
      healthSection: 'FAQ',
    })
  }

  const onFAQSectionClick = (title: string) => {
    tracker.trackEvent({
      event: track.faqClicked,
      faqQuestion: title,
    })
  }

  return (
    <FirstViewWaypoint onView={onView}>
      <section className={styles.FAQSection}>
        <div className={styles.grid_layout}>
          <h2 className={styles.title}>Ask us anything</h2>
          <p className={styles.subtitle}>
            We&apos;re here to make this process as simple and stress-free as
            possible.
          </p>
          <Accordion>
            <Accordion.Section
              title="I placed an order. What happens now?"
              onExpand={() =>
                onFAQSectionClick('I placed an order. What happens now?')
              }
            >
              Getting your breast pump works a little differently than a retail
              purchase since Babylist Health orders go through insurance. Before
              we can ship your pump, we need to make sure we have all the right
              paperwork and information so your insurance approves your claim.
              This process includes getting a valid prescription and making sure
              we ship your pump out within your plan's approved shipping window.{' '}
              <a
                href="https://healthbabylist.zendesk.com/hc/en-us/articles/15037809178651-I-placed-an-order-What-happens-now"
                rel="noopener"
                target="_blank"
              >
                Learn more.
              </a>
            </Accordion.Section>
            <Accordion.Section
              title="Can I return my breast pump?"
              onExpand={() => onFAQSectionClick('Can I return my breast pump?')}
            >
              Insurance breast pumps are medical-grade products and aren&apos;t
              eligible for return or exchange. If you need to make changes to
              your order, reach out to us at{' '}
              <a
                href="mailto:health@babylist.com"
                rel="noopener"
                target="_blank"
              >
                health@babylist.com
              </a>{' '}
              &mdash; we&apos;ll be happy to update your order as long as it
              hasn&apos;t already been sent to our warehouse for processing.{' '}
              <a
                href="https://healthbabylist.zendesk.com/hc/en-us/articles/12903014268443-Can-I-return-my-breast-pump"
                rel="noopener"
                target="_blank"
              >
                Learn more.
              </a>
            </Accordion.Section>
            <Accordion.Section
              title="Can I order more than one breast pump?"
              onExpand={() =>
                onFAQSectionClick('Can I order more than one breast pump?')
              }
            >
              Most insurance companies cover just one breast pump per pregnancy.
              If multiple orders are placed through insurance—even with
              different suppliers—you&apos;ll be responsible for the cost of any
              extra pumps. To avoid unexpected costs, please ensure you only
              place one pump order through insurance.{' '}
              <a
                href="https://healthbabylist.zendesk.com/hc/en-us/articles/9420913735451--Can-I-order-more-than-one-breast-pump"
                rel="noopener"
                target="_blank"
              >
                Learn more.
              </a>
            </Accordion.Section>
            <Accordion.Section
              title="What's an insurance upgrade fee?"
              onExpand={() =>
                onFAQSectionClick("What's an insurance upgrade fee?")
              }
            >
              An upgrade fee is the cost you pay for choosing a breast pump with
              additional features, like rechargeable batteries, tote bags or
              wearable designs. Insurance covers the standard pump model, but
              upgraded models often require an additional fee. This fee is
              non-refundable and will be the patient&apos;s responsibility.{' '}
              <a
                href="https://healthbabylist.zendesk.com/hc/en-us/articles/9423523568667-What-s-an-insurance-upgrade-fee"
                rel="noopener"
                target="_blank"
              >
                Learn more.
              </a>
            </Accordion.Section>
            <Accordion.Section
              title="I have two insurances, can I get two pumps?"
              onExpand={() =>
                onFAQSectionClick('I have two insurances, can I get two pumps?')
              }
            >
              When you have two insurance plans, one is designated as your
              primary and the other as your secondary. This doesn&apos;t mean
              you&apos;ll receive double coverage for items like breast pumps.
              Typically, you can receive one breast pump per pregnancy, which
              must be billed to your primary insurance first. Your secondary
              insurance may only provide coverage if your primary plan denies
              the claim.{' '}
              <a
                href="https://healthbabylist.zendesk.com/hc/en-us/articles/13665475028507-I-have-two-insurances-can-I-get-two-pumps"
                rel="noopener"
                target="_blank"
              >
                Learn more.
              </a>
            </Accordion.Section>
            <Accordion.Section
              title="When will my order ship?"
              onExpand={() => onFAQSectionClick('When will my order ship?')}
            >
              Insurance plans have specific timelines for when your breast pump
              can ship. Some require us to wait until you're 30&mdash;70 days
              away from your due date, while others let us ship as soon as we
              have all your paperwork completed. You can find your ship date and
              tracking info on your Order Status page in your email
              confirmation.{' '}
              <a
                href="https://healthbabylist.zendesk.com/hc/en-us/articles/9512865881115-When-will-my-order-ship"
                rel="noopener"
                target="_blank"
              >
                Learn more.
              </a>
            </Accordion.Section>
            <Accordion.Section
              title="Am I eligible for replacement parts and milk storage bags?"
              onExpand={() =>
                onFAQSectionClick(
                  'Am I eligible for replacement parts and milk storage bags?'
                )
              }
            >
              Most active insurance plans will fully reimburse milk storage bags
              and breast pump replacement parts starting 30 days after giving
              birth and continuing up to one year. If you&apos;ve purchased your
              pump with us, we&apos;ll email you once you&apos;re eligible to
              receive parts. Visit our{' '}
              <a href="/health/parts">replacement parts homepage</a> if
              you&apos;ve placed your pump order outside of Babylist Health.{' '}
              <a
                href="https://healthbabylist.zendesk.com/hc/en-us/articles/22819765227291-Am-I-eligible-for-replacement-parts-and-milk-storage-bags"
                rel="noopener"
                target="_blank"
              >
                Learn more.
              </a>
            </Accordion.Section>
          </Accordion>
        </div>
      </section>
    </FirstViewWaypoint>
  )
}

export default FAQSection
