import React, { useRef, useState } from 'react'
import classNames from 'classnames'

import css from './Section.styles.scss'
import { SectionProps } from './Section.types'

import { RightChevron } from '../../../icons'

const Section: React.FC<SectionProps> = ({ title, children, onExpand }) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const toggleOpen = () => {
    const isOpening = !isOpen
    setIsOpen(isOpening)

    if (isOpening && onExpand) {
      onExpand()
    }

    if (ref.current == null) return

    // Set the maxHeight to trigger the expand/collapse animation.
    if (isOpening) {
      ref.current.style.maxHeight = `${ref.current.scrollHeight}px`
    } else {
      ref.current.style.maxHeight = '0px'
    }
  }

  return (
    <div className={css.Section}>
      <button className={css.title} type="button" onClick={toggleOpen}>
        <span
          className={classNames(css.title_icon, {
            [css.title_icon__open]: isOpen,
          })}
        >
          <RightChevron />
        </span>
        {title}
      </button>
      <div className={css.expandable_block} hidden={!isOpen} ref={ref}>
        <div className={css.content}>{children}</div>
      </div>
    </div>
  )
}

export default Section
