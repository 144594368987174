import { FC } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from 'baby-design'
import { PARTS_HOME_PATH } from 'bl-health/constants'
import { FirstViewWaypoint } from 'bl-health/Homepage/components/FirstViewWaypoint/FirstViewWaypoint'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import styles from './HereToHelpSection.styles.scss'
import Sticker from './components/Sticker/Sticker'

const PUMP_CLOVER_IMAGE_URL =
  'https://images.babylist.com/image/upload/v1731536676/DME/dme_here_to_help_section_pump_clover.png'

const HereToHelpSection: FC = () => {
  const tracker = useTracking()

  const onView = () => {
    tracker.trackEvent({
      event: track.homepageSectionViewed,
      healthSection: 'Parts',
    })
  }

  const onPartsClick = () => {
    tracker.trackEvent({
      event: track.freeReplacementPartsClicked,
      healthSection: 'Parts',
    })
  }

  return (
    <FirstViewWaypoint onView={onView}>
      <div className={styles.HereToHelpSection}>
        <div className={styles.grid_layout}>
          <div className={styles.graphic_container}>
            <div className={styles.graphic}>
              <div className={styles.sticker}>
                <Sticker>
                  <h3 className={styles.sticker_text}>We're here to help</h3>
                </Sticker>
              </div>
              <img
                alt="Breast Pump"
                className={styles.pump_image}
                src={PUMP_CLOVER_IMAGE_URL}
              />
            </div>
          </div>
          <div className={styles.section_content}>
            <h2 className={styles.section_text}>
              No matter where you got your pump, we've got you covered with
              replacement parts.
            </h2>
            <Button
              href={PARTS_HOME_PATH}
              size="md"
              variant="primary"
              onClick={onPartsClick}
            >
              Get new pump parts
            </Button>
          </div>
        </div>
      </div>
    </FirstViewWaypoint>
  )
}

export default HereToHelpSection
