// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from 'baby-design'
import { getPopularPumps } from 'bl-health/requests'
import { useQuery } from '@tanstack/react-query'
import NiceModal from '@ebay/nice-modal-react'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import { FirstViewWaypoint } from 'bl-health/Homepage/components/FirstViewWaypoint/FirstViewWaypoint'
import { FC } from 'react'
import styles from './PopularPumpsSection.styles.scss'
import ProductTile from './components/ProductTile/ProductTile'
import ProductModal from './components/ProductModal/ProductModal'
import { INSURANCE_PICKER_SECTION_ID } from '../InsurancePickerSection/InsurancePickerSection'

const PopularPumpsSection: FC = () => {
  const { data: popularPumps } = useQuery<APIResponse.PopularPumps[]>({
    queryFn: getPopularPumps,
    queryKey: ['popularPumps'],
  })

  const handleProductClick = (product: APIResponse.PopularPumps) => {
    NiceModal.show(ProductModal, { product })
  }

  const tracker = useTracking()

  const onView = () => {
    tracker.trackEvent({
      event: track.homepageSectionViewed,
      healthSection: 'Pump preview',
    })
  }

  const onSeeCoverageClick = () => {
    tracker.trackEvent({
      event: track.homepageCtaClicked,
      healthSection: 'Pump preview',
    })
  }

  return (
    <FirstViewWaypoint onView={onView}>
      <section className={styles.PopularPumpsSection}>
        <div className={styles.grid_layout}>
          <div className={styles.header_container}>
            <h2 className={styles.header} id="popular-pumps-header">
              Popular pumps from trusted brands
            </h2>
            <p className={styles.subtitle}>
              Discover the highest-quality pumps, handpicked by our team of
              experts.
            </p>
          </div>
          {popularPumps && popularPumps.length > 0 && (
            <article className={styles.product_scroller}>
              {popularPumps.map((product: APIResponse.PopularPumps) => (
                <ProductTile
                  key={product.id}
                  product={product}
                  onClick={() => handleProductClick(product)}
                />
              ))}
            </article>
          )}
          <div className={styles.button_container}>
            <Button
              href={`#${INSURANCE_PICKER_SECTION_ID}`}
              size="md"
              variant="tertiary"
              onClick={onSeeCoverageClick}
            >
              See what your insurance covers
            </Button>
          </div>
        </div>
      </section>
    </FirstViewWaypoint>
  )
}

export default PopularPumpsSection
