import { Box, Typography } from '@material-ui/core'
import Button from 'baby-ui/foundation/Button'
import useStyles from './styles'

const NotFoundView = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        margin="auto"
        maxWidth={360}
        py={6}
      >
        <Box
          // @ts-ignore
          alt="Spilled milk"
          component="img"
          src="//images.babylist.com/image/upload/v1671138271/DME/Spilled_Milk_404_Icon.svg"
        />
        <Typography className={classes.textOutlined} component="h1">
          oops!
        </Typography>
        <Typography gutterBottom color="primary" variant="h4">
          <b>Page not found</b>
        </Typography>
        <Typography
          gutterBottom
          className={classes.description}
          color="primary"
          variant="h6"
        >
          We're sorry we can't find the page you are looking for.
        </Typography>
        <Button
          fullWidth
          className={classes.cta}
          color="secondary"
          href="/health"
          variant="contained"
        >
          Take Me Home
        </Button>
      </Box>
    </div>
  )
}

export default NotFoundView
