// eslint-disable-next-line import/no-extraneous-dependencies
import { Button, Gauge, Image, Modal, StarRating } from 'baby-design'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { INSURANCE_PICKER_SECTION_ID } from 'bl-health/Homepage/sections/InsurancePickerSection/InsurancePickerSection'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import styles from './ProductModal.styles.scss'
import { convertScoreToPercentage } from './utils'
import PumpType from '../PumpType/PumpType'

const ProductModal = NiceModal.create(
  ({ product }: { product: APIResponse.PopularPumps | null }) => {
    const modal = useModal()
    const tracker = useTracking()
    if (!product) return null

    const { brand, images, name, pumpType, rating, reviewCount, suctionScore } =
      product

    const handleCheckEligibility = () => {
      tracker.trackEvent({
        event: track.homepageCtaClicked,
        healthSection: 'Pump preview',
      })
      modal.hide()
      document.getElementById(INSURANCE_PICKER_SECTION_ID)?.scrollIntoView()
    }

    return (
      <Modal showCloseButton handleClose={modal.hide} isOpen={modal.visible}>
        <section className={styles.ProductModal}>
          <div className={styles.pump_container}>
            <div className={styles.image_container}>
              <Image alt={`${brand} ${name}`} src={images.thumbnailUrl} />
            </div>
            <div className={styles.content_block}>
              <div className={styles.header_container}>
                <h3
                  aria-label={`${brand} ${name}`}
                  className={styles.brand}
                  title={`${brand} ${name}`}
                >
                  {brand}
                </h3>
                <p className={styles.name}>{name}</p>
                <div className={styles.rating_container}>
                  <StarRating rating={rating} reviewCount={reviewCount} />
                </div>
              </div>
              <div className={styles.details_container}>
                <div className={styles.detail}>
                  <div className={styles.details_header}>Pump type</div>
                  <PumpType pumpType={pumpType} />
                </div>
                <div className={styles.vertical_divider} />
                <div className={styles.detail}>
                  <div className={styles.details_header}>Suction</div>
                  <Gauge percentage={convertScoreToPercentage(suctionScore)} />
                </div>
              </div>
            </div>
          </div>
          <Button size="md" variant="primary" onClick={handleCheckEligibility}>
            Check eligibility
          </Button>
        </section>
      </Modal>
    )
  }
)

export default ProductModal
