const Sticker: React.FC<React.HTMLAttributes<SVGElement>> = () => (
  <svg
    fill="none"
    height="140"
    viewBox="0 0 140 140"
    width="140"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56.973 13.7553L56.9741 13.7522C57.4874 12.2985 59.3692 11.9166 60.4124 13.0593L72.235 26.0278L72.8857 26.7416L73.6216 26.116L86.8574 14.8646L86.8581 14.864C88.0343 13.8623 89.8539 14.4763 90.1842 15.9822L93.9409 33.1255L94.1476 34.0688L95.0976 33.8951L112.187 30.7705L112.19 30.77C113.71 30.4881 114.979 31.9285 114.509 33.3998L114.509 33.4008L109.189 50.1265L108.896 51.0469L109.806 51.3715L126.166 57.2099L126.169 57.211C127.623 57.7243 128.005 59.6061 126.862 60.6493L113.893 72.4719L113.18 73.1226L113.805 73.8585L125.057 87.0943L125.057 87.095C126.059 88.2712 125.445 90.0908 123.939 90.4211L106.796 94.1778L105.852 94.3845L106.026 95.3345L109.151 112.424L109.151 112.427C109.433 113.947 107.993 115.216 106.521 114.746L106.52 114.746L89.7947 109.426L88.8743 109.133L88.5497 110.042L82.7113 126.403L82.7102 126.406C82.1969 127.86 80.3151 128.242 79.2719 127.099L67.4493 114.13L66.7986 113.416L66.0626 114.042L52.8269 125.293L52.8262 125.294C51.6499 126.296 49.8303 125.682 49.5001 124.176L45.7434 107.033L45.5367 106.089L44.5867 106.263L27.497 109.388L27.4945 109.388C25.9741 109.67 24.7053 108.23 25.175 106.758L25.1753 106.757L30.4956 90.0316L30.7884 89.1112L29.8788 88.7866L13.5184 82.9482L13.5153 82.9471C12.0616 82.4338 11.6797 80.552 12.8224 79.5088L25.7909 67.6862L26.5047 67.0355L25.8791 66.2995L14.6277 53.0638L14.6271 53.0631C13.6254 51.8868 14.2394 50.0672 15.7453 49.737L32.8886 45.9803L33.8319 45.7736L33.6582 44.8236L30.5336 27.7338L30.5331 27.7314C30.2512 26.211 31.6916 24.9422 33.1629 25.4119L33.1639 25.4122L49.8896 30.7325L50.81 31.0253L51.1346 30.1157L56.973 13.7553Z"
      fill="#FF5D38"
      stroke="url(#paint0_linear_7333_22120)"
      strokeWidth="2"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_7333_22120"
        x1="58.7741"
        x2="80.9102"
        y1="11.3616"
        y2="128.796"
      >
        <stop stopColor="#FFB8A8" />
        <stop offset="1" stopColor="#D199C8" />
      </linearGradient>
    </defs>
  </svg>
)

export default Sticker
