interface CircleVectorProps {
  className?: string
}

const CircleVector: React.FC<CircleVectorProps> = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="105"
    viewBox="0 0 105 105"
    width="105"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M104 52.5C104 80.9427 80.9427 104 52.5 104C24.0574 104 1 80.9427 1 52.5C1 24.0573 24.0574 1 52.5 1C80.9427 1 104 24.0573 104 52.5Z"
      opacity="0.5"
      stroke="#6E3264"
      strokeWidth="2"
    />
  </svg>
)

export default CircleVector
