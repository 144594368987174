import { Factory } from 'fishery'
import faker from 'faker'

import { ShipmentStatusSummary } from 'src/types/reservation'

const shipmentStatusSummaryFactory = Factory.define<ShipmentStatusSummary>(
  () => ({
    currentState: 'preparing',
    currentStateDisplay: 'ships',
    relevantDate: faker.date.past().toISOString(),
  })
)

export default shipmentStatusSummaryFactory
