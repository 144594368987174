import { FC, FormEvent, useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button, Select, TextLink } from 'baby-design'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Chevron } from 'baby-design/icons'
import {
  PUMP_INSURANCE_PICKER_FORM_ACTION,
  PARTS_HOME_PATH,
} from 'bl-health/constants'
import { useQuery } from '@tanstack/react-query'
import { getInsuranceCatalog } from 'bl-health/requests'
import NiceModal from '@ebay/nice-modal-react'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import {
  formFieldClickedEvent,
  freeReplacementPartsClickedEvent,
  pumpCatalogSelectedEvent,
} from 'bl-health/events'
import styles from './InsurancePicker.styles.scss'
import useInsuranceEligibilityStatus, {
  InsuranceEligibilityFailureReasons,
  InsuranceEligibilityResponseStatus,
  InsuranceEligibilityResponseType,
} from './hooks/useInsuranceEligibilityStatus'
import { REACT_QUERY_KEY_GET_INSURANCE_CATALOG } from './constants'
import StateNotSupportedModal from './modals/StateNotSupportedModal/StateNotSupportedModal'
import InsuranceNotSupportedModal from './modals/InsuranceNotSupportedModal/InsuranceNotSupportedModal'

type SelectOptionType = { label: string; value: number }

const InsurancePicker: FC = () => {
  const determineInsuranceEligibility = useInsuranceEligibilityStatus()
  const tracker = useTracking()
  const { data: insuranceCatalog } = useQuery({
    queryKey: REACT_QUERY_KEY_GET_INSURANCE_CATALOG,
    queryFn: getInsuranceCatalog,
  })

  const [stateSelectedOption, setStateSelectedOption] =
    useState<SelectOptionType | null>(null)
  const stateId = stateSelectedOption && stateSelectedOption.value
  const [insuranceSelectedOption, setInsuranceSelectedOption] =
    useState<SelectOptionType | null>(null)
  const insuranceId = insuranceSelectedOption && insuranceSelectedOption.value

  const [insuranceEligibility, setInsuranceEligibility] =
    useState<InsuranceEligibilityResponseType | null>(null)

  const stateOptions = stateOptionsFromInsuranceCatalog(insuranceCatalog)
  const selectInsuranceOptions = [
    {
      label: 'Top plans:',
      options: insuranceOptionsFromInsuranceCatalog(insuranceCatalog, stateId, {
        promoted: true,
      }),
    },
    {
      label: 'All plans:',
      options: insuranceOptionsFromInsuranceCatalog(insuranceCatalog, stateId),
    },
  ]

  const onStateChange = (selectedStateOption: SelectOptionType | null) => {
    formFieldClickedEvent(tracker)({
      formName: track.FormName.INSURANCE_FORM,
      formFieldName: track.FormFieldName.INSURANCE_STATE,
      healthVersion: 'v2',
    })
    setStateSelectedOption(selectedStateOption)
    setInsuranceSelectedOption(null)
    setInsuranceEligibility(null)
  }

  const onInsuranceChange = (
    selectedInsuranceOption: SelectOptionType | null
  ) => {
    formFieldClickedEvent(tracker)({
      formName: track.FormName.INSURANCE_FORM,
      formFieldName: track.FormFieldName.INSURANCE_PROVIDER,
      healthVersion: 'v2',
    })
    setInsuranceSelectedOption(selectedInsuranceOption)
    setInsuranceEligibility(null)
  }

  const submitClickEvent = () => {
    const insuranceCarrierValue = insuranceCarrierFromCatalog(
      insuranceCatalog,
      insuranceSelectedOption?.value
    )

    pumpCatalogSelectedEvent(tracker)({
      healthVersion: 'v2',
      insuranceProvider: insuranceCarrierValue?.company || '',
      insurancePayer: insuranceCarrierValue?.payer,
      insuranceProviderId: insuranceCarrierValue?.id || 0,
      insuredState: stateSelectedOption?.label || '',
      emailProvided: false,
    })
  }

  const onSubmit = (e: FormEvent) => {
    const eligibility = determineInsuranceEligibility({ stateId, insuranceId })
    setInsuranceEligibility(eligibility)
    submitClickEvent()

    if (stateSelectedOption) {
      localStorage.setItem('state_name', stateSelectedOption.label)
    }

    if (eligibility.status === InsuranceEligibilityResponseStatus.FAILURE) {
      e.preventDefault()

      if (
        eligibility.errors.includes(
          InsuranceEligibilityFailureReasons.STATE_NOT_SUPPORTED
        )
      ) {
        NiceModal.show(StateNotSupportedModal)
      } else if (
        eligibility.errors.includes(
          InsuranceEligibilityFailureReasons.INSURANCE_NOT_SUPPORTED
        )
      ) {
        NiceModal.show(InsuranceNotSupportedModal)
      }
    }
  }

  const stateSelectHasError =
    insuranceEligibility?.status ===
      InsuranceEligibilityResponseStatus.FAILURE &&
    insuranceEligibility.errors.includes(
      InsuranceEligibilityFailureReasons.STATE_NOT_FOUND
    )

  const insuranceSelectHasError =
    insuranceEligibility?.status ===
      InsuranceEligibilityResponseStatus.FAILURE &&
    insuranceEligibility.errors.includes(
      InsuranceEligibilityFailureReasons.INSURANCE_NOT_FOUND
    )

  return (
    <form
      action={PUMP_INSURANCE_PICKER_FORM_ACTION}
      className={styles.InsurancePicker}
      method="post"
      onSubmit={onSubmit}
    >
      <Select<SelectOptionType>
        RightIcon={() => <Chevron />}
        isDisabled={stateOptions.length === 0}
        label="Shipping state"
        name="state_id"
        options={stateOptions}
        placeholder="State"
        size="md"
        status={
          stateSelectHasError
            ? { type: 'error', message: 'Please select your state' }
            : undefined
        }
        value={stateSelectedOption}
        onChange={onStateChange}
      />
      <Select<SelectOptionType>
        RightIcon={() => <Chevron />}
        formatGroupLabel={(group) => (
          <h6 className={styles.insurance_select_label}>{group.label}</h6>
        )}
        isDisabled={!stateId}
        label="Insurance carrier"
        name="insurance_id"
        options={selectInsuranceOptions}
        placeholder="Carrier"
        size="md"
        status={
          insuranceSelectHasError
            ? { type: 'error', message: 'Please select your insurance' }
            : undefined
        }
        value={insuranceSelectedOption}
        onChange={onInsuranceChange}
      />
      <Button size="md" type="submit" variant="primary">
        Check eligibility
      </Button>
      <div className={styles.extra_info}>
        If you're already pumping, we can help you get{' '}
        <TextLink
          underline
          href={PARTS_HOME_PATH}
          size="sm"
          variant="eggplant"
          onClick={() =>
            freeReplacementPartsClickedEvent(tracker)({
              healthSection: 'Insurance form',
            })
          }
        >
          free replacement parts
        </TextLink>{' '}
        through insurance, too!
      </div>
    </form>
  )
}

const stateOptionsFromInsuranceCatalog = (
  insuranceCatalog: APIResponse.InsuranceCatalog[] | undefined
) =>
  insuranceCatalog?.map((state) => ({
    value: state.id,
    label: state.stateName,
  })) || []

const insuranceOptionsFromInsuranceCatalog = (
  insuranceCatalog: APIResponse.InsuranceCatalog[] | undefined,
  stateId: number | null,
  options?: { promoted: boolean }
) => {
  const selectedState = insuranceCatalog?.find((state) => state.id === stateId)

  if (selectedState === undefined) return []

  const insurances = !options?.promoted
    ? selectedState.insurances
    : selectedState.insurances.filter((insurance) => insurance.promoted)

  return insurances.map((insurance) => ({
    value: insurance.id,
    label: insurance.company,
  }))
}

const insuranceCarrierFromCatalog = (
  insuranceCatalog: APIResponse.InsuranceCatalog[] | undefined,
  insuranceId: number | undefined
): APIResponse.Insurance | undefined => {
  if (!insuranceCatalog || insuranceId === undefined) return undefined

  const state = insuranceCatalog.find((st) =>
    st.insurances.some((insurance) => insurance.id === insuranceId)
  )
  return state?.insurances.find((insurance) => insurance.id === insuranceId)
}

export default InsurancePicker
