import GuideCard from 'baby-ui/compounds/GuideCard'
import DisplayGrid from 'baby-ui/compounds/DisplayGrid'
import { useTracking } from 'lib/babylistHealthAnalytics'
import { guideClickedEvent } from 'bl-health/events'
import guidesToGuideCardPropsAdapter from './guidesToGuideCardPropsAdapter'
import { Guide } from '../../../global'

export interface GuidesWidgetProps {
  guides: Guide[]
  numGuides?: number
  openGuidesInNewTab?: boolean
}

export default function GuidesWidget({
  guides,
  openGuidesInNewTab = false,
  numGuides,
}: GuidesWidgetProps) {
  const tracker = useTracking()

  function fireGuideClickedEvent(url: string) {
    guideClickedEvent(tracker)({
      postId: 'N/A',
      postRevision: 0,
      postSlug: url,
    })
  }

  return (
    <>
      <DisplayGrid
        disableContainer
        breakpoints={{ xs: 12, md: 6 }}
        component={GuideCard}
        data={guidesToGuideCardPropsAdapter(
          numGuides ? guides.slice(0, numGuides) : guides
        )}
        every={({ href }) => ({
          linkTarget: openGuidesInNewTab ? '_blank' : undefined,
          key: href,
          onClick: () => {
            fireGuideClickedEvent(href)
          },
        })}
      />
    </>
  )
}
