import React from 'react'

import css from './Gauge.styles.scss'

import { GaugeProps } from './Gauge.types'
import Meter from './components/Meter/Meter.svg'

const Gauge: React.FC<GaugeProps> = ({
  percentage,
  'aria-label': ariaLabel,
  'aria-valuetext': ariaValueText,
}) => {
  let label = ''
  if (percentage < 0.33) {
    label = 'Low'
  } else if (percentage < 0.66) {
    label = 'Med.'
  } else {
    label = 'High'
  }

  return (
    <span
      aria-label={ariaLabel}
      aria-valuemax={1}
      aria-valuemin={0}
      aria-valuenow={percentage}
      aria-valuetext={ariaValueText}
      className={css.Gauge}
      role="meter"
    >
      <Meter percentage={percentage} />
      <p className={css.label}>{label}</p>
    </span>
  )
}

export default Gauge
