import { Factory } from 'fishery'
import faker from 'faker'
import { Cart } from 'src/types/cart'
import cartItemFactory from './cartItemFactory'

export default Factory.define<Cart>(() => ({
  isEligibleForRegistryDiscount: faker.datatype.boolean(),
  checkoutTotals: {
    itemsSubtotal: faker.commerce.price(),
    registryDiscount: faker.commerce.price(),
    saleTotalAmountOff: faker.commerce.price(),
    shipping: faker.commerce.price(),
    subtotal: faker.commerce.price(),
    subtotalBeforeSales: faker.commerce.price(),
    tax: faker.commerce.price(),
    total: faker.commerce.price(),
    userCredit: faker.commerce.price(),
  },
  applyRegistryDiscount: faker.datatype.boolean(),
  items: [cartItemFactory.build()],
  productSurcharge: faker.datatype.number(),
  requiresReservationCreation: faker.datatype.boolean(),
}))
