import { track, Tracker } from 'lib/babylistHealthAnalytics'
import { decorateEventPayload } from './decorateEventPayload'

export interface PumpCatalogSelectedEventMetadata {
  insuranceProvider: string
  insurancePayer?: string
  insuranceProviderId: number
  insuredState: string
  emailProvided: boolean
  healthVersion?: string
}

export const pumpCatalogSelectedEvent =
  (tracker: Tracker) =>
  ({
    insuranceProvider,
    insurancePayer,
    insuranceProviderId,
    insuredState,
    emailProvided,
    healthVersion,
  }: PumpCatalogSelectedEventMetadata) => {
    const payload = decorateEventPayload({
      event: track.pumpCatalogSelected,
      insuranceProvider,
      insurancePayer,
      insuranceProviderId,
      insuredState,
      emailProvided,
      healthVersion,
    })
    tracker.trackEvent(payload)
  }
