import NiceModal, { useModal } from '@ebay/nice-modal-react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Modal } from 'baby-design'

const InsuranceNotSupportedModal = NiceModal.create(() => {
  const modal = useModal()

  return (
    <Modal
      showCloseButton
      handleClose={modal.hide}
      isOpen={modal.visible}
      subTitle="It's likely that you are covered under your insurance! Please contact them to discuss your options."
      title="We're working hard to support your insurance, but we're not quite there yet."
    >
      {null}
    </Modal>
  )
})

export default InsuranceNotSupportedModal
